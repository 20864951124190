import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { callUmbracoApi } from "../fetch";
import Loading from "../components/Loading";
import VerifyCard from "../components/VerifyCard";

export default function Verify() {
  const { name } = useParams();
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!content) {
      let url = "/content/url?url=/verify/" + name;
      callUmbracoApi(url)
        .then(response => {
          setContent(response); console.log("RESPONSE: ", response);
          setLoading(false);
          setError(null);
        })
        .catch((error) => {
          setError(error?.message);
          console.log("Error: ", error);
        });
    }
  }, [content]);

  return (
    <>
      {
        loading ? ( 
          <Loading />
        ) : (
          error ? (
            "An error occurred."
          ) : (
            <>
              <Helmet>
                <title>{!content ? '' : content.metaTitle}</title>
                <meta name="description" content={!content ? '' : content.metaDescription} />
                <meta name="keywords" content={!content ? '' : content.metaKeywords} />
              </Helmet>
              <div className="container mx-auto my-2">
                <VerifyCard content={content} />
              </div>
            </>
          )
        )
      }
    </>
  )
}
