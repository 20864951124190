import { Fragment } from "react";
import { Popover, Transition } from '@headlessui/react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { ChevronDownIcon, HomeIcon} from '@heroicons/react/24/outline'
import { Link } from "react-router-dom";
import Logo from './Logo';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navigation() {
  return (
    <Popover className="container mx-auto grid grid-cols-2 lg:grid-cols-8 lg:px-4 items-center my-2 lg:my-0">
      <div className="bg-goj-primary ml-2 lg:ml-0">
        <Link id="logo-home-link" to="/" title="Onlines services hub">
          <Logo />
        </Link>
      </div>
      <div className="bg-goj-primary hidden lg:flex lg:col-span-4">
        <Popover.Group as="nav" className="hidden space-x-2 lg:flex">
          <div className="ml-4 pb-4 px-4 pt-6 border-b-4 border-b-goj-primary hover:border-b-goj-primary">
            <Link id="osh-home-link" to="/" className="text-goj-white visited:text-goj-white hover:no-underline">Home</Link>
          </div>
          <Popover className="relative group  pb-4 px-4 pt-6 border-b-4 border-b-goj-primary hover:border-b-goj-primary">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    'inline-flex items-center bg-goj-primary group-hover:bg-goj-primary focus:ring-goj-focus-light'
                  )}
                >
                  <span className="text-goj-white group-hover:bg-goj-primary">Cards</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-goj-white' : 'text-goj-white',
                      'ml-2 h-5 w-5 group-hover:bg-goj-primary'
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                {/* Cards flyout menu */}
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute h-auto overflow-scroll overflow-y-hidden overflow-x-hidden z-20 -ml-4 mt-3 w-screen lg:w-fit max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                    <div className="rounded-lg shadow-lg ring-1 ring-goj-charcoal ring-opacity-5">
                      <div className="relative grid gap-1 bg-goj-white p-4">
                        {/* TODO: replace with component */}
                        <a href="/registration-card" 
                           className="group flex items-center px-3 py-2 rounded text-goj-charcoal hover:underline active:text-goj-charcoal active:underline visited:text-goj-charcoal"
                        >
                          <span className="truncate">Registration</span>
                        </a>
                        <a href="/medical-card" 
                           className="group flex items-center px-3 py-2 rounded text-goj-charcoal hover:underline active:text-goj-charcoal active:underline visited:text-goj-charcoal"
                        >
                          <span className="truncate">Medical</span>
                        </a>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </Popover.Group>
      </div>

      {/* Responsive menu */}
      <div className="lg:hidden flex items-center lg:col-span-2 justify-end mr-2">
        <Popover.Button className="inline-flex items-center justify-center rounded bg-goj-primary p-2 text-goj-white hover:underline border border-goj-primary mr-1 focus:border-goj-focus-dark focus:bg-goj-focus-dark focus:outline-none focus:ring-1 focus:ring-goj-focus-light focus:text-goj-white">
          <span>Menu</span>
        </Popover.Button>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute w-full inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden z-50 h-screen overflow-scroll overflow-x-hidden">
          <div className="divide-y-2 divide-goj-gray-light rounded-lg bg-goj-white shadow-lg ring-1 ring-goj-charcoal ring-opacity-5">
            <div className="pt-5 pb-6">
              <div className="flex items-center justify-end px-4">
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded bg-goj-white p-2 text-goj-primary hover:underline border border-goj-primary focus:border-goj-focus-dark focus:bg-goj-focus-dark focus:outline-none focus:ring-1 focus:ring-goj-focus-light focus:text-goj-white">
                    <span>Close menu</span>
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <div className="w-full flex items-center text-goj-charcoal visited:text-goj-charcoal border-b p-4 border-b-goj-gray-light">
                      <HomeIcon className="h-6 w-6 flex-shrink-0 text-goj-charcoal" />
                      <a href="/" className="w-full text-left ml-4 text-goj-charcoal visited:text-goj-charcoal">
                        <strong>Home</strong>
                      </a>
                    </div>
                    <AccordionItem>
                        {({ open }) => (
                          <>
                            <AccordionHeader className={`${open ? 'bg-goj-primary-very-light-tint' : 'bg-goj-white'} w-full flex items-center text-goj-charcoal visited:text-goj-charcoal border-b p-4 border-b-goj-gray-light`}>
                              <span href="#" className="w-full text-left ml-4 text-goj-charcoal visited:text-goj-charcoal">
                                <strong>Cards</strong>
                              </span>
                              <svg className={`w-6 h-6 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg>
                            </AccordionHeader>
                            <AccordionBody className={`${open ? 'border-b border-goj-gray-light' : ''}`}>
                              <div className="p-5 font-light">
                                {/* TODO: replace with component */}
                                <a href="/registration" 
                                  className="group flex items-center px-3 py-2 rounded text-goj-charcoal hover:underline active:text-goj-charcoal active:underline visited:text-goj-charcoal"
                                >
                                  <span className="truncate">Registration</span>
                                </a>
                                <a href="/medical" 
                                  className="group flex items-center px-3 py-2 rounded text-goj-charcoal hover:underline active:text-goj-charcoal active:underline visited:text-goj-charcoal"
                                >
                                  <span className="truncate">Medical</span>
                                </a>
                              </div>
                            </AccordionBody>
                          </>
                        )}
                      </AccordionItem>
                  </Accordion>
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
