const cmsProjectAlias = process.env.REACT_APP_CMS_PROJECT_ALIAS;
const cmsEndPoint = process.env.REACT_APP_CMS_ENDPOINT;

export const callUmbracoApi = async(apiEndpoint) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept-Language", "en-GB");
  myHeaders.append("umb-project-alias", cmsProjectAlias);
  var options = {
    method: 'GET',
    headers: myHeaders
  };

  return fetch(cmsEndPoint + apiEndpoint, options)
      .then(response => response.json())
      .catch(error => console.log(error));
}
export const callApi = async(apiEndpoint) => {
  const headers = new Headers();
  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(apiEndpoint, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}
export const callApiWithParams = async(apiEndpoint, params) => {
  var url = new URL(apiEndpoint)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const headers = new Headers();
  const options = {
    method: "GET",
    headers: headers
  };

  console.log("callApiWithParams", url, options)
  return fetch(url, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}
export const postApi = async(apiEndpoint, data) => {
  const headers = new Headers();
  const options = {
    method: "POST",
    headers: headers,
    body:JSON.stringify(data)
  };
 
  return fetch(apiEndpoint, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}
