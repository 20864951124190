import { useLocation } from "react-router";
import Footer from "./Footer";
import Navigation from "./Navigation";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Breadcrumb from "../components/Breadcrumb";

export default function PageLayout(props) {
  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const pages = [];
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children !== "Home") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
    return elem
  })
  return (
    <div className="flex flex-col min-h-screen">
      <header className="sticky z-50 top-0 bg-goj-primary items-center border border-b-goj-primary py-2 lg:py-0 px-2 md:px-0">
        <Navigation />
      </header>
      <Breadcrumb pages={pages} currentPage={location.pathname} />
      <main className="container w-full mx-auto px-4 mb-12">
        {props.children}
      </main>
      <Footer />
    </div>
  );
};
