import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export default function DigitalCard({ card }) {
  const cardStyle = {
    backgroundColor: card.display.backgroundColor,
    color: card.display.textColor
  };

  return (
    <div className="card items-center border-0 rounded-lg border-goj-gray-light px-5 pt-1 pb-4 bg-goj-gray-light-super flex-col">
      {
        (card) ?
          <div>
            <div>
              <span className="inline-block relative w-64 rounded-lg mt-4 h-36 text-sm pl-2 pt-1" style={cardStyle}>
                <p className="absolute top-0 right-0 mt-2 mr-2">{card.display.title}</p>
                <img src={card.display.logo.uri} alt={card.display.logo.description} width="80" />
                <p className="absolute bottom-0 left-0 mb-2 ml-2">{card.display.issuedBy}</p>
              </span>
            </div>

            <div className="flex items-center">
              <h3 className="mt-3">{card.name}</h3>
            </div>
            <div>
              <p>{card.description}</p>
            </div>
            <div>
              <div className="flex items-center mt-2 text-goj-secondary">
                <Link to={card.name.toLowerCase().replace(/\s+/g, '-')} state={card}
                  className="flex items-center w-full lg:w-auto justify-center rounded-lg border bg-goj-secondary hover:underline hover:bg-goj-secondary-light py-3 px-6 text-goj-white visited:text-goj-white shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light">
                    Verify {card.name.toLowerCase()} <ChevronRightIcon className="h-6 mb-px inline" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div> : null
      }
    </div>
  )
}