import { callApi, postApi } from "../fetch";
import { useState, useEffect } from 'react';
import Loading from "./Loading";
import { Link, useLocation } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/20/solid';
const digitalCardPresentationEndpoint = process.env.REACT_APP_DIGITAL_CARD_PRESENTATION
async function postPresentationRequest(props) {
  const data = { includeQRCode: true, cardTypeId: props.id };
  return postApi(digitalCardPresentationEndpoint, data);
}
async function getStatusOfPresentationRequest(requestId) {
  try {
    return callApi(digitalCardPresentationEndpoint + "/" + requestId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default function VerifyCard(props) {
  const location = useLocation();
  const cardType = location.state;
  console.log("cardType", cardType);
  const content = props.content;
  const mainHeading = content?.mainHeading;
  const mainContent = content?.mainContent;
  const [serviceError, setServiceError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    messageHeading: '',
    message: '',
  })
  const [responseStatus, setResponseStatus] = useState('waiting');
  const [presentationRequest, setPresentationRequest] = useState({});
  const [loading, setLoading] = useState(true);
  function pollPresentationRequest(response) {
    let counter = 0;
    console.log("pollPresentationRequest count", counter)
    const intervalId = setInterval(() => {
      getStatusOfPresentationRequest(response).then((status) => {
        setResponseStatus(status.requestStatus);
        if (status.requestStatus === "presentation_verified" || counter >= 60) {
          setPresentationRequest(status?.claims); 
          console.log("DEBUG: Status", status);
          console.log("DEBUG: Claims", status?.claims);
          clearInterval(intervalId);
        }
        counter++;
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }
  // Poll API every 5 seconds until response status is 'success' or 'failure'
  useEffect(() => {
    setLoading(true);
    postPresentationRequest(cardType)
      .then((response) => {
        setPresentationRequest(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    console.log("presentationRequest state", presentationRequest?.state)
    if (presentationRequest?.state) {
      pollPresentationRequest(presentationRequest.state);
    }
  }, [presentationRequest]);

  //initial load
  if (loading) {
    return <Loading />;
  }

  // service error
  if (serviceError) {
    return (
      <>
        <h1>{errorMessage.messageHeading}</h1>
        <p>{errorMessage.message}</p>
      </>
    );
  }

  // waiting
  if (responseStatus === 'request_retrieved') {
    return (
      <>
        <h1>Verify a registration card</h1>
        <p>Follow the instructions on the device you scanned the QR code.</p>
        <div className="flex justify-center">
          <h2>QR code scanned successfully, awaiting response from user</h2>
        </div>
        <div className="flex justify-center">
          <Loading message="" height="h-auto" align="justify-center" fill="fill-goj-secondary" />
        </div>
        <div className="flex justify-center">
          <button className="justify-center mt-8 text-goj-secondary hover:underline" onClick={() => window.location.reload(false)}>Refresh</button>
        </div>
      </>
    );
  }

  // success
  if (responseStatus === 'presentation_verified') {
    return (
      <>
        <h1>Success! The digital card has been verified</h1>
        <p>The following details have been shared by the user</p>
        <div className="mb-10">
          <div>
            <div className="border-b">
              <p className="mt-1 mb-0 py-1">Description</p>
              <p className="w-full lg:w-auto font-semibold py-1 text-goj-charcoal mb-1">{presentationRequest?.description ?? `Registration card by Government of Jersey`}</p>
            </div>
            <div className="border-b">
              <p className="mt-1 mb-0 py-1">Social security number</p>
              <p className="w-full lg:w-auto font-semibold py-1 text-goj-charcoal mb-1">{presentationRequest?.ssn}</p>
            </div>
            <div className="border-b">
              <p className=" mt-1 mb-0 py-1">Name</p>
              <p className="w-full lg:w-auto font-semibold py-1 text-goj-charcoal mb-1">{presentationRequest?.firstName} {presentationRequest?.lastName}</p>
            </div>
            <div className="border-b">
              <p className="mt-1 mb-0 py-1">Residential status</p>
              <p className="w-full lg:w-auto font-semibold py-1 text-goj-charcoal mb-1">{presentationRequest?.residentialStatus}</p>
            </div>
            {presentationRequest?.conditions ?
            <div className="border-b">
              <p className="mt-1 mb-0 py-1">Conditions</p>
              <p className="w-full lg:w-auto font-semibold py-1 text-goj-charcoal mb-1">{presentationRequest?.conditions}</p>
            </div>
            : ""}
            <div className="border-b">
              <p className="mt-1 mb-0 py-1">Expiry</p>
              <p className="w-full lg:w-auto font-semibold py-1 text-goj-charcoal mb-1">{presentationRequest?.cardExpiry}</p>
            </div>
            <div className="border-b">
              <p className="mt-1 mb-0 py-1">Status</p>
              <p className="w-full lg:w-auto font-semibold py-1 text-goj-charcoal mb-1">{presentationRequest?.cardStatus}</p>
            </div>
          </div>
        </div>
        <Link to="/"
          className="w-full md:w-52 justify-center rounded border bg-goj-secondary py-3 px-8 text-goj-white hover:bg-goj-secondary-light visited:text-goj-white shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"> Verify another card<ChevronRightIcon className="h-7 mb-px inline" aria-hidden="true" />
        </Link>
      </>
    );
  }

  // qr
  console.log("presentationRequest",presentationRequest)
  return (
    <div>
      <h1>{mainHeading}</h1>
      <p>Scan the QR code with the Microsoft Authenticator app on the users device.</p>
      <div className="flex justify-center">
        <img className="mx-auto w-48" src={presentationRequest?.qrCode} alt="QR code for authentication" />
      </div>
      <div className="flex justify-center">
        <p>In the app on the card holder's device, open the Verified ID tab, tap on the QR code scan icon and ask them to follow the on-screen instructions</p>
      </div>
      <div className="flex justify-center">
        <p>
          <a href="#">Watch a video to see how this works</a>
        </p>
      </div>
    </div>
  )
}