import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function ErrorAlert({ messageHeading, message }) {
  return (
    <div className="rounded-md bg-goj-primary-very-light-tint px-3 py-3">
      <div className="text-goj-primary text-lg flex items-center">
        <ExclamationTriangleIcon className="w-6 h-6 mr-1" />
        {messageHeading}
      </div>
      {message ?
        <div className="text-goj-primary pb-3">
          <ul className="list-disc pl-5">
            <li>{message}</li>
          </ul>
        </div> 
        : null
      }
    </div>
  )
}
