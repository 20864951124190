import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { callApi } from '../fetch';
import DigitalCard from './DigitalCard';
import Loading from './Loading';

const digitalCardTypesEndpoint = process.env.REACT_APP_DIGITAL_CARD_TYPE_FETCH;

export default function DigitalCardTypes() {
  // mock data
  const mockData = JSON.parse('[{"id":"e53936df-9141-c229-33af-002cedcc0294","name":"Registration card","description":"Verify a user\'s registration card is valid and confirm the residential and employment status","display":{"backgroundColor":"#B60011","description":"This is your Verifiable Credential","issuedBy":"Government of Jersey","textColor":"#FFFFFF","title":"Jersey resident","logo":{"description":"GOJ crest","uri":"https://media.umbraco.io/dev-government-of-jersey-heartcore-professional/sw3dnnyt/goj-crest-white.png"}}},{"id":"2","name":"Medical card","description":"Description here for a medical card","display":{"backgroundColor":"#FFFFFF","description":"This is your Verifiable Credential","issuedBy":"Government of Jersey","textColor":"#B60011","title":"Medical card","logo":{"description":"GOJ crest","uri":"https://media.umbraco.io/dev-government-of-jersey-heartcore-professional/mrtg4glq/goj-crest-red.png"}}}]');
  console.log("MOCK", mockData);

  // context  //TODO: Consider if we need context for digital cards
  // const user = useContext(UserContext);
  // const codes = user.codes;
  // const fullProfile = user.fullProfile;

  // state
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [serviceError, setServiceError] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    messageHeading: '',
    message: '',
  })

  function getDigitalCards() {
    callApi(digitalCardTypesEndpoint)
    .then(response => {
      console.log("DIGITAL CARD TYPES",response);
      if (response?.length > 0) {
        setData(response);
      }
      else {
        //setData(mockData);
        setData([]);
        setDisplayError(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          messageHeading: "Digital cards",
          message: "There are currently no digital cards available to display."
        }));
      }
    })
    .catch((error) => {
      console.log(error);
      setData([]);
      setServiceError(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        messageHeading: "Unable to retrieve cards",
        message: "Digital cards are currently unavailable."
      }));
    })
  }

  // load existing data
  useEffect(() => {
    if (!data) {
      setLoading(true);
      getDigitalCards();
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }, [data])

  if (loading) {
    return <Loading />
  }

  if (serviceError || displayError) {
    return (
      <div>
        <p><strong>{errorMessage.messageHeading}</strong><br />{errorMessage.message}</p>
      </div>
    )
  }

  return (
    <div className="grid gap-4 lg:grid-cols-2 mx-auto my-4 w-full">
      {/* {mockData ? mockData.map((card) => {
        return (
          <DigitalCard key={card.id} card={card} />
        )
      }) : "No digital cards available"} */}
      {data ? data.map((card) => {
        return (
          <DigitalCard key={card.id} card={card} />
        )
      }) : "No digital cards available"}
    </div>
  )
}