import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

export default function Breadcrumb(props) { 
  let {pages}=props;
  let {currentPage}=props;
  //console.log("currentPage",currentPage)

  return (
    <nav className="bg-goj-gray-light-super pt-1 pb-2" aria-label="Breadcrumb">
      <div className="container mx-auto px-4">
        <ol className="flex-auto items-center space-x-2 pt-2 pb-1">
          <li className="inline">
            <div className="inline">
              <Link to="/" className="text-goj-charcoal hover:underline hover:text-goj-charcoal active:text-goj-charcoal visited:text-goj-charcoal">
                Home
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name} className="inline"><span data-page={page.href}></span>
              <div className="inline items-center">
                <ChevronRightIcon className="h-4 w-4 mb-px inline" aria-hidden="true" />
                {(currentPage===page.href)?
                (<span
                  href={page.href}
                  className="ml-4 text-goj-charcoal"
                  aria-current='page'
                >
                  {page.name}
                </span>)
                : ( <Link
                  to={page.href}
                  className="ml-4 text-goj-charcoal hover:underline hover:text-goj-charcoal active:text-goj-charcoal visited:text-goj-charcoal"
                  aria-current={currentPage===page.href ? 'page' : undefined}
                >
                  {page.name}
                </Link>)}
              </div>
            </li>
          ))}
        </ol>
      </div>
    </nav>
  )
}