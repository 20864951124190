import ParseHtml from "../utils/ParseHtml";
import DigitalCardTypes from "./DigitalCardTypes";

export default function HomeContent(props) {
  const content = props.content;
  const mainHeading = content?.mainHeading;
  const mainContent = content?.mainContent;
  return (
    <div>
      <h1>{mainHeading}</h1>
      <article>{mainContent ? ParseHtml(mainContent) : null}</article>
      <DigitalCardTypes />
    </div>
  )
}