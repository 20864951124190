import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import PageLayout from "./components/PageLayout";
import ScrollToTop from "./utils/ScrollToTop";
import Verify from "./pages/Verify";

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:name" element={<Verify />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <PageLayout>
        <Pages />
      </PageLayout>
    </Router>
  );
}

